<div *ngIf="SpotPhotos">
  <!-- Mobile -->
  <div *ngIf="isMobile" class="row">
    <div class="col-md-12">
      <div
        *ngIf="listingType == 'PREMIUM'"
        id="floatingPagination"
        class="premium__floating"
        style="margin-left: 10px;"
      >
        <div class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
          <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
        </div>
      </div>
      <div
        *ngIf="listingType == 'FEATURED'"
        id="floatingPagination"
        class="premium__floating"
        style="margin-left: 10px;"
      >
        <div class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/auto_awesome.svg" alt="" />
          <label class="premium__floating__text">{{ 'global.platinum' | translate }}</label>
        </div>
      </div>
      <div
        *ngIf="listingType == 'PROMOTED'"
        id="floatingPagination"
        class="premium__floating"
        style="margin-left: 10px;"
      >
        <div style="width: auto !important;" class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/Announcement.png" alt="" *ngIf="hasVacancy" />
          <img style="width: 22px;" src="assets/images/icons/offMarket.svg" alt="" *ngIf="!hasVacancy" />
          <label class="premium__floating__text" *ngIf="hasVacancy">{{
            'global.propertyWithVacancy' | translate
          }}</label>
          <label class="premium__floating__text" *ngIf="!hasVacancy">{{ 'global.offMarket' | translate }}</label>
        </div>
      </div>
      <div
        id="floatingPagination"
        class="premium__floating"
        style="margin-left: 10px; width: 91%; margin-top: 20px;"
        *ngIf="pageName === 'company'"
      >
        <div class="premium__floating__section" style="background-color: initial">
          <button class="seePhotos btn-color-1 btn-border" (click)="openGalleryImg()">
            {{ 'buildinsDetail.seePhotos' | translate }}
          </button>
        </div>
      </div>

      <img
        [ngStyle]="cssImgMobile()"
        [src]="getMediumImg(SpotPhotos ? getCroppedOrOriginalImage(SpotPhotos[0]) : null)"
        default="assets/placeholder-image.png"
        [alt]="propertyName"
        [title]="propertyName"
      />
      <div class="col-md-12" style="padding-right: 0px;" *ngIf="pageName !== 'company'">
        <button class="seePhotos btn-color-1 btn-border" (click)="openGalleryImg()">
          {{ 'buildinsDetail.seePhotos' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!--   Desktop -->

  <div [ngStyle]="cssComponent()" *ngIf="!isMobile" class="col-md-12" style="padding: 0px;">
    <div style="padding: 0px; float: left;width: 66%">
      <div *ngIf="listingType == 'PREMIUM'" id="floatingPagination" class="premium__floating">
        <div class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
          <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
        </div>
      </div>
      <div *ngIf="listingType == 'FEATURED'" id="floatingPagination" class="premium__floating">
        <div class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/auto_awesome.svg" alt="" />
          <label class="premium__floating__text">{{ 'global.platinum' | translate }}</label>
        </div>
      </div>
      <div *ngIf="listingType == 'PROMOTED'" id="floatingPagination" class="premium__floating">
        <div style="width: auto !important;" class="premium__floating__section">
          <img style="width: 22px;" src="assets/images/icons/offMarket.svg" alt="" *ngIf="!hasVacancy" />
          <img style="width: 22px;" src="assets/images/icons/Announcement.png" alt="" *ngIf="hasVacancy" />
          <label class="premium__floating__text" *ngIf="!hasVacancy">{{ 'global.offMarket' | translate }}</label>
          <label class="premium__floating__text" *ngIf="hasVacancy">{{
            'global.propertyWithVacancy' | translate
          }}</label>
        </div>
      </div>

      <div [ngStyle]="cssImg1()" class="img1">
        <img
          [src]="getMediumImg(SpotPhotos ? getCroppedOrOriginalImage(SpotPhotos[0]) : null)"
          default="assets/placeholder-image.jpg"
          [alt]="propertyName"
          [title]="propertyName"
        />
      </div>

      <div class="entire_building__floating" *ngIf="entireSale && !offMarket">
        <div class="entire_building__floating__section">
          <label class="entire_building__floating__text">{{ 'buildinsDetail.entireBuilding' | translate }}</label>
        </div>
      </div>
      <div class="entire_building__floating" *ngIf="offMarket && type == 'L'">
        <div class="entire_building__floating__section">
          <label class="entire_building__floating__text">100% LEASED</label>
        </div>
      </div>

      <div class="entire_building__floating" *ngIf="offMarket && type != 'L'">
        <div class="entire_building__floating__section">
          <label class="entire_building__floating__text">OFF MARKET</label>
        </div>
      </div>
    </div>
    <div style="padding-right: 0px;float: right;width: 32.6%;">
      <div class="col-md-12" style="margin-bottom: 10px;padding-right: 0px;">
        <img
          *ngIf="SpotPhotos && SpotPhotos?.length > 1"
          [ngStyle]="cssImg2()"
          [src]="getMediumImg(SpotPhotos ? getCroppedOrOriginalImage(SpotPhotos[1]) : null)"
          default="assets/placeholder-image.jpg"
          [alt]="propertyName"
          [title]="propertyName"
        />
        <div
          *ngIf="
            (SpotPhotos && SpotPhotos?.length < 2 && listingType == 'PROMOTED') ||
            (!SpotPhotos && listingType == 'PROMOTED')
          "
          style="border: 1px solid #E0E2E7"
          [ngStyle]="cssImg2()"
        >
          <img class="img-promoted" src="assets/images/promotedCard.png" alt="" />
          <br />
          <div
            class="col-md-12"
            style="text-align: center;
          font-size: 20px;"
          >
            <span>{{ 'buildinsDetail.promotedImg' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 28px;padding-right: 0px;">
        <button
          *ngIf="SpotPhotos && SpotPhotos?.length > 2"
          class="seePhotos btn-color-1 btn-border"
          (click)="openGalleryImg()"
        >
          {{ 'buildinsDetail.seePhotos' | translate }}
        </button>
        <img
          *ngIf="SpotPhotos && SpotPhotos?.length > 2"
          [ngStyle]="cssImg3()"
          [src]="getMediumImg(SpotPhotos ? getCroppedOrOriginalImage(SpotPhotos[2]) : null)"
          default="assets/placeholder-image.jpg"
          [alt]="propertyName"
          [title]="propertyName"
        />
        <div
          *ngIf="
            (SpotPhotos && SpotPhotos?.length < 3 && listingType == 'PROMOTED') ||
            (!SpotPhotos && listingType == 'PROMOTED')
          "
          style="border: 1px solid #E0E2E7"
          [ngStyle]="cssImg3()"
        >
          <img class="img-promoted" src="assets/images/promotedCard.png" alt="" />
          <br />
          <div
            class="col-md-12"
            style="text-align: center;
          font-size: 20px;"
          >
            <span>{{ 'buildinsDetail.promotedImg' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
